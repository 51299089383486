<template>
  <div>
    <el-dialog
      :visible.sync="isDialog"
      append-to-body
      :width="width + '%'"
      :before-close="cancellationFn"
    >
      <div slot="title" style="color: #409eff">{{ costTitle }}</div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <div class="mainList_operation_search" style="margin-bottom: 8px">
            <div class="search-title">
              <span class="title">单据编号:</span>
              <el-input v-model="parameter.expenseNumber" placeholder="请输入单据编号"></el-input>
            </div>
            <div class="search-button">
              <el-button
                type="primary"
                size="medium"
                icon="el-icon-search"
                @click="pageChangeHandler(1)"
                >搜索</el-button
              >
              <el-button type="warning" size="medium" icon="el-icon-search" @click="handleReset"
                >清空</el-button
              >
            </div>
          </div>
          <el-table
            v-loading="loading"
            :data="expenditureList"
            style="width: 100%"
            max-height="400"
            border
            ref="selectTable"
            @row-click="rowClickFn"
            @selection-change="handleCurrentChange"
          >
            <el-table-column
              fixed="left"
              :selectable="checkSelectSet"
              type="selection"
              label="选择"
              width="60"
            >
            </el-table-column>
            <!-- 折叠行 -->
            <el-table-column type="expand" width="50">
              <template slot-scope="scope">
                <el-table
                  fixed="left"
                  v-show="scope.row.paymentDetail?.length > 0"
                  :data="scope.row.paymentDetail"
                  style="margin-left: 59px; width: 93%"
                  border
                >
                  <el-table-column
                    prop="typeOne"
                    :show-overflow-tooltip="false"
                    min-width="150"
                    label="费用科目一"
                    align="center"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="typeTwo"
                    :show-overflow-tooltip="false"
                    min-width="150"
                    label="费用科目二"
                    align="center"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="typeThree"
                    :show-overflow-tooltip="false"
                    min-width="150"
                    label="费用科目三"
                    align="center"
                  >
                  </el-table-column>
                  <el-table-column prop="cost" min-width="110" label="金额（元）" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.cost | applyAmount }}
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <!-- 编号 -->
            <el-table-column
              prop="expenseNumber"
              label="单据编号"
              align="center"
              width="175"
            ></el-table-column>
            <el-table-column
              prop="applicantName"
              sortable
              label="申请人"
              width="100"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="deptName"
              sortable
              width="130"
              label="费用所属部门"
              align="center"
            ></el-table-column>

            <el-table-column
              prop="collectionUnit"
              sortable
              min-width="280"
              :show-overflow-tooltip="false"
              label="收款单位"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="sumCost" width="120" sortable label="申请金额" align="center">
              <template slot-scope="scope">
                {{ scope.row.sumcost | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="projectBudget"
              width="120"
              sortable
              label="申请时间"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.createdDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              prop="statusName"
              :show-overflow-tooltip="false"
              width="150"
              label="流程进度"
              align="center"
            >
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="pageChangeHandler"
            @size-change="handleSizeChange"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            :total="parameter.total"
          >
          </el-pagination>
        </div>
      </div>

      <span name="footer" slot="footer" style="display: flex; justify-content: center">
        <el-button
          type="success"
          style="min-width: 120px; margin: 0 25px"
          :loading="loading"
          @click="saveFn"
          >确 定</el-button
        >
        <el-button
          type="warning"
          style="min-width: 120px; margin: 0 25px"
          plain
          :loading="loading"
          @click="cancellationFn"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {
    ExpenditureAdvanceDetails: () => import('@/components/funds/expenditure-advance-details.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 60,
    },
    pitchOnList: {
      type: Array,
      default: function () {
        return []
      },
    },
    deleteIds: {
      type: Array,
      default: function () {
        return []
      },
    },
    bidCostType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isDialog: false,
      loading: false,
      expenditureList: [],
      companyTypeList: [],
      saveArr: [],
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        expenseNumber: null,
        tabFee: this.bidCostType || '',
      },
    }
  },
  watch: {
    isShow: {
      immediate: true,
      handler: function (newVal) {
        this.isDialog = newVal
      },
    },
  },
  computed: {
    costTitle() {
      let str = ''
      if (this.bidCostType == 'businessExpenses') {
        str = '商务报销'
      }
      if (this.bidCostType == 'businessPayments') {
        str = '商务外协费用'
      }
      if (this.bidCostType == 'projectExpenses') {
        str = '项目报销'
      }
      if (this.bidCostType == 'projectApplications') {
        str = '项目付款申请'
      }
      if (this.bidCostType == 'paymentExpenses') {
        str = '公共费用报销'
      }
      if (this.bidCostType == 'paymentApplications') {
        str = '公共费用付款申请'
      }
      return str
    },
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return 0
      }
    },
  },
  created() {
    this.getData()
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.selectTable) {
        this.$refs.selectTable.clearSelection()
        this.pitchOnList.forEach(v => {
          this.$refs.selectTable.toggleRowSelection(v, true)
        })
      }
    })
  },
  methods: {
    handleReset() {
      this.parameter.expenseNumber = null
      this.getData()
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    checkSelectSet(row, index) {
      //row 就是每行的数据
      let data = this.pitchOnList //这是多选按钮保存的数据
      //return : false  就是禁用
      //return : true  不禁用
      return data.findIndex(item => item.id == row.id) === -1
    },
    rowClickFn(row) {
      if (this.pitchOnList.some(v => v.id === row.id)) return
      this.$refs.selectTable.toggleRowSelection(row)
    },
    handleCurrentChange(val) {
      this.saveArr = val
    },
    getData() {
      this.loading = true
      this.$api.bid
        .getAllSpendingPaymentList(this.parameter)
        .then(res => {
          this.expenditureList = res.data?.records || []
          this.parameter.total = res.data?.total || 0
          this.loading = false
          if (this.deleteIds.length > 0) {
            // 说明存在有id的被删除
            this.expenditureList = [...this.expenditureList, ...this.deleteIds]
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    saveFn() {
      this.loading = true
      this.$emit('saveFn', this.saveArr)
      this.$emit('update:isShow', false)
      this.loading = false
    },
    cancellationFn() {
      this.loading = true
      this.$emit('update:isShow', false)
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/.el-table-column--selection {
  .is-disabled {
    .el-checkbox__inner::before {
      content: '✓'; /* 使用 Unicode 打勾字符 */
      font-size: 12px; /* 自定义字体大小 */
      position: absolute;
      color: #c0c4cc;
      left: 2px;
      top: 0;
    }
  }
}

.mainList_operation_search {
  display: flex;
  align-items: center;
  padding-right: 20px;
  .search-title {
    display: flex;
    align-items: center;
    .title {
      display: inline-block;
      width: 100px;
    }
  }
  .search-button {
    margin-left: 20px;
  }
}

.el-pagination {
  margin-top: 10px;
  text-align: right;
}
</style>
